.bridge-connect-wrap{

	padding-left: var(--wrap-small);
	padding-right: var( --wrap-small);

	display: flex;
	  flex-direction: column;
	  align-items: center;
	  justify-content: center;

	  min-height: 100vh;
}

.bridge-connect-wrap .bridge-content-wrap{
	max-width: var(--small-max-width);
}

.hew-logo{
	max-width:150px;
	margin-left:-0.5em;
	display:block;
	margin-left: auto;
	margin-right: auto;

	position: relative;
	left:-1em;
}

.bridge-list{
	list-style:none;
	padding-left:0;
}
.bridge-list-item{
	padding:2em;
	border-radius:1em;
	background-color:gray;
}