@import-normalize; /* bring in normalize.css styles */

/* rest of app styles */
:root {
    --main-bg-color: #101010;
    --site-max-width: 1000px;
    --small-max-width: 600px;
    --wrap-small: 2em;
}


body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: var(--main-bg-color);
    color:white;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h1{
    margin: 1.2em 0 0.67em 0;
}

h2{
    margin-top: 1.7em;
}
h3{
    margin-top: 1.9em;
}
h4{
    margin-top: 2em;
}
h5{
    margin-top: 2em;
}
h6{
    margin-top: 2em;
}

