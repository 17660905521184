.btn{
    padding: 0.8em 1.6em;
    border: none;
    background-color: white;
    border-radius: 0.9em;
    cursor: pointer;
	transition: background-color 500ms ease-out;
}

.btn:hover,
.btn:focus{
	background-color: #cacaca;
	cursor: pointer;
    transition: background-color 100ms ease-out;
}
